<template>
	<full-screen-page :title="$t('view.auth.login.title')">
		<lazy-alert v-if="hasJustRegistered" type="success" no-spacing class="mb-32">
			{{ $t('text.successful_registration', {email: registeredEmail}) }}
		</lazy-alert>

		<lazy-alert v-else-if="hasJustConfirmed"
					no-spacing class="mb-32"
					type="success">
			{{ $t('text.confirmed_registration') }}
		</lazy-alert>

		<div v-else class="flex items-center mb-32">
			<span class="text-sm mr-4">{{ $t('question.not_registered') }}</span>
			<nuxt-link :to="{path: $web('auth.register'), query: $route.query}" class="text-sm link">{{ $t('action.create_free_account') }}</nuxt-link>
		</div>

		<ajax-form :action="login" :data="loginData" class="mt-8 mb-32" method="POST" v-model:loading="isLoading">
			<form-input v-model="loginData.email"
						:label="$t('label.email')"
						name="email"
						inverted
						large
						:disabled="isLoading"
						type="email"
						:rules="{required: true}">
			</form-input>
			<form-input v-model="loginData.password" :rules="{required: true}" :disabled="isLoading"
						inverted
						large
						:label="$t('label.password')" type="password" name="password"/>

			<div class="flex items-center">
				<span class="text-sm mr-4">{{ $t('question.no_password') }}</span>
				<nuxt-link :to="$web('auth.reset_password')" class="text-sm link">{{ $t('action.reset_password') }}</nuxt-link>
			</div>

			<btn type="submit" color="success" large rounded-large shadow :loading="isLoading" :disabled="isLoading" class="w-full mt-32">
				<span>{{ $t('action.login') }}</span>
			</btn>
		</ajax-form>

	</full-screen-page>
</template>

<script setup lang="ts">

const route = useRoute();
const {$web, $device, $auth, $analytics} = useNuxtApp();
const {t} = useI18n();
const config = useRuntimeConfig();

const loginData = ref({
	email: route.query.email,
	password: null,

	participations: route.query.participations,
	creator_participations: route.query.creator_participations,
	clubs: route.query.clubs, // TODO: Deprecated
	join: route.query.join,
	member: route.query.member,

	// For following models via login
	follow_type: route.query.follow_type,
	follow_id: route.query.follow_id
});

const hasJustRegistered = ref(route.query.registered);
const hasJustConfirmed = ref(route.query.registration_confirmed || route.query.registration_invalid_token);
const registeredEmail = ref(route.query.email);

// Must be resolved via client side as we don't have access to the redirect param on SSR
function resolveRedirectPath() {
	return route.query.redirect && ![$web('auth.login'), '/'].includes(route.query.redirect) ? route.query.redirect : null;
}

// We might use the login page for authorizing users for our external apps, e.g., resultastic or Clubmanager, and redirect
// them back to the app with an auth token whereby we want to display a loading state
const hasInitialAuthRedirect = ref($auth.loggedIn);
const isLoading = ref(hasInitialAuthRedirect.value);
watch(() => $auth.token, (token) => {
	if (!hasInitialAuthRedirect.value) {
		return;
	}

	if (token) {
		let redirectPath = resolveRedirectPath();

		// Only our own apps are allowed to be redirected to
		const qualifyForRedirectWithToken = redirectPath && redirectPath.startsWith('https') && redirectPath.includes(config.public.domain)
		if (qualifyForRedirectWithToken) {
			redirectPath += redirectPath.includes('?') ? '&' : '?';
			redirectPath += `auth=${token}`;
		}

		navigateTo(redirectPath ?? $web('dashboard'), {
			external: true
		});
	} else {
		hasInitialAuthRedirect.value = false;
	}
}, {
	immediate: true // Required for CSR
});

function login(data, callback) {
	const redirectPath = resolveRedirectPath();
	$auth.login(data).then(() => {
		$analytics.trackEvent('login', {method: 'email'});

		navigateTo(redirectPath ?? $web('dashboard'), {
			external: redirectPath && redirectPath.startsWith('http')
		});
	}).catch(error => {
		callback(false, error);
	})
}

// No 'guest' middleware as we might want to redirect to external apps if the user is already logged in
definePageMeta({
	layout: 'full',
});

useMeta({
	title: t('view.auth.login.title'),
	noIndex: true // When crawled, this page causes many requests from crawlers as it's linked on every page
});

</script>

